(function ($) {

    'use strict';

    var $document = $(document),
        on = function (event, selector, fn) {
            $document.on(event, selector, fn);
        };

    $.urlParam = function (name, str) {

        if (!str) {
            str = window.location.search;
        }

        var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(str);

        return (results !== null) ? results[1] || 0 : false;
    };

    $.getVideoImage = function (path, secs, callback) {
        var me = this, video = document.createElement('video');
        video.onloadedmetadata = function () {
            if ('function' === typeof secs) {
                secs = secs(this.duration);
            }
            this.currentTime = Math.min(Math.max(0, (secs < 0 ? this.duration : 0) + secs), this.duration);
        };
        video.onseeked = function (e) {
            var canvas = document.createElement('canvas');
            canvas.height = video.videoHeight;
            canvas.width = video.videoWidth;
            var ctx = canvas.getContext('2d');
            ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
            callback.call(me, canvas.toDataURL(), this.currentTime, e);
        };
        video.onerror = function (e) {
            console.log(e);
        };
        video.src = path;
    };

    window.HELP_IMPROVE_VIDEOJS = false;

    $(function () {

        var draw_image = function () {

            var $this = $(this),
                video = $this[0],
                $container = $this.closest('.video-preview'),
                $img_container = $container.prev('.video').find('.image-container'),
                $image = $('<img class="img-responsive img-preview" />');

            if (!$container.length) {
                return;
            }

            console.log($this);
            console.log(video);


            $.getVideoImage(video.currentSrc, 2, function (self, dataUrl) {
                console.log(dataUrl);
                $img_container.append($image.attr('src', dataUrl));
            });

            $container.remove();
        }, handle_navigation = function (next) {

            var $this = $(this),
                $container = $this.closest('.pg-enter-the-code'),
                $parent = $container.find('ul.side-navigation'),
                current_page = $container.find('input.the-page').val(),
                is_last_page = $container.find('ul.pagination > li > a.next.page-numbers > i.fa-forward').length === 0,
                is_first_page = $container.find('ul.pagination > li > a.prev.page-numbers > i.fa-backward').length === 0,
                $masonry = $container.find('.masonry'),
                max = $masonry.find('.grid-item').length - 1;

            $parent.data('next', next);

            $parent.find('li').addClass('hidden');

            // Only one Item available do not show any button
            if (max === 0 && current_page === '1') {
                return;
            }

            // Last Item happens to be the only one, we can only move backwards from here
            // show only left anchor
            if (is_last_page && max === 0) {
                $parent.find('li.left-anchor').removeClass('hidden');
                return;
            }

            // Do not show right button if we have the last image
            if (is_last_page && max === next) {
                $parent.find('li.left-anchor').removeClass('hidden');
                return;
            }

            // Only show right anchor on first page first Item if we currently show
            // the first item
            if (is_first_page && next === 0) {
                $parent.find('li.right-anchor').removeClass('hidden');
                return;
            }

            $parent.find('li').removeClass('hidden');
        };

        on('blur', '.pg-enter-the-code input.the-code', function () {
            var $input = $(this);

            $input
                .removeClass('valid focused')
                .val('');
        });

        on('click', 'a.admin-removal-button', function (e) {
            e.preventDefault();

            var $this = $(this),
                $loader = $this.find('i.fa'),
                $container = $this.closest('.pg-portfolio'),
                $images = $container.find('input.admin-checkbox:checked'),
                $alert = $container.find('.alert'),
                msg = _.template(pg_i18n.confirm_admin_delete);

            $alert.addClass('hidden');

            if (!$images.length) {
                return;
            }

            if (!confirm(msg({'size': $images.length}))) {
                return;
            }

            $loader.attr('class', 'fa fa-spin fa-spinner');

            var data = [];

            $images.each(function () {
                var $img = $(this);

                data.push($img.val());
            });

            _.bind($.pg_log, this, 'admin-remove-images')(data);

            $.ajax({
                type: 'POST',
                cache: false,
                url: pg_data.ajaxurl,
                data: {
                    'images': data,
                    'code': $.urlParam('PBGADMIN'),
                    'action': 'pg-admin-delete',
                    'csrf': pg_data['pg-admin-delete']
                }

            }).done(function (resp) {

                $loader.attr('class', 'fa fa-trash');

                if (resp.class) {
                    $alert.attr('class', resp.class);
                }

                if (resp.message) {
                    $alert.html(resp.message).removeClass('hidden');
                }

                $images.each(function () {
                    $(this).closest('.grid-item').remove();
                });

            }).error(function () {

                $loader.attr('class', 'fa fa-trash');

                $alert.removeClass('hidden');

            });

        });

        //handle basic images
        on('click', '.pg-portfolio .masonry .grid-item a.image', function (e, auto_scroll) {

            e.preventDefault();

            var $this = $(this),
                $parent = $this.closest('.pg-portfolio'),
                $target = $parent.find('.thumbnail.full-view .media-container'),
                $media = $parent.find('.thumbnail.full-view .social-media .button-container'),
                $gallery = $parent.find('.the-gallery'),
                $buttons = $this.find('.social-media-container ul.buttons').clone(),
                $loader = $('<i class="fa fa-spin fa-spinner"></i>'),
                $image = $('<img class="img-responsive" />'),
                idx = $this.closest('.grid-item').index(),
                $t = $(e.target);

            if ($t.is('label')) {

                var $i = $t.closest('.pg-admin-menu').find('input');

                $i.prop('checked', !$i.prop('checked'));

                return;
            }

            if ($('#pbg-video-player').length) {
                videojs('pbg-video-player').dispose();
            }

            _.bind($.pg_log, this, 'image-preview-clicked')($this.find('img').data('preview'));

            $target
                .empty()
                .append($loader)
                .append($image);

            _.bind(handle_navigation, $this)(idx);

            $image
                .addClass('loading')
                .on('load', function () {
                    $loader.remove();
                    $image.removeClass('loading');
                    $media.empty().append($buttons.removeClass('hidden'));

                    if (!$gallery.data('autoscroll')) {
                        return;
                    }

                    if (auto_scroll === false) {
                        return;
                    }

                    $('html, body').animate({
                        scrollTop: $parent.offset().top
                    }, 500);

                })
                .attr('src', $this.find('img').data('preview'));
        });

        // Handle html5 video
        on('click', '.pg-portfolio .masonry .grid-item a.video', function (e, auto_scroll) {

            e.preventDefault();

            var $this = $(this),
                $t = $(e.target),
                $parent = $this.closest('.pg-portfolio'),
                $gallery = $parent.find('.the-gallery'),
                $target = $parent.find('.thumbnail.full-view .media-container'),
                $embed = $('<div class="embed-responsive embed-responsive-16by9"/>'),
                $media = $parent.find('.thumbnail.full-view .social-media .button-container'),
                $buttons = $this.find('.social-media-container ul.buttons').clone(),
                $video = $('<video id="pbg-video-player" class="video-js vjs-default-skin vjs-big-play-centered vjs-16-9"/>'),
                idx = $this.closest('.grid-item').index(),
                video_definition = {
                    'src': $this.data('source'),
                    'type': $this.data('type'),
                },
                $source = $('<source />', video_definition);

            if ($t.is('label')) {

                var $i = $t.closest('.pg-admin-menu').find('input');

                $i.prop('checked', !$i.prop('checked'));

                return;
            }

            if ($('#pbg-video-player').length && typeof videojs !== "undefined") {
                videojs('pbg-video-player').dispose();
            }

            _.bind($.pg_log, this, 'video-preview-clicked')(video_definition);

            $target.empty();

            $video.append($source);
            $embed.append($video);
            $target.append($embed);

            if (typeof videojs !== "undefined") {
                videojs('pbg-video-player', {
                    controls: true,
                    autoplay: false,
                    preload: 'metadata',
                    fluid: true
                });
            }

            $media.empty().append($buttons.removeClass('hidden'));

            _.bind(handle_navigation, $this)(idx);

            if (!$gallery.data('autoscroll')) {
                return;
            }

            if (auto_scroll === false) {
                return;
            }

            $('html, body').animate({
                scrollTop: $parent.offset().top
            }, 500);
        });

        // Process the image and masonry functionality
        on('ajax.pg.loaded', '.pg-enter-the-code', function () {
            var $container = $(this),
                $b = $('body'),
                $g = $container.find('.masonry'),
                $image = $container.find('.thumbnail.full-view'),
                $nav = $container.find('ul.side-navigation'),
                highlight = $b.data('highlight-index') || 'first';

            $container.find('video').each(draw_image);

            if (highlight === 'last') {

                $b.removeData('highlight-index');

                $g.find('.grid-item:last').find('a').trigger('click');
            }

            if (highlight === 'first') {

                $b.removeData('highlight-index');

                $g.find('.grid-item:first').find('a').trigger('click');
            }

            document.activeElement.blur();

            $("input").blur();

            $g.imagesLoaded().progress(function () {

                $g.css({
                    opacity: 0,
                    visibility: "visible"
                }).animate({
                    opacity: 1.0
                }, 300);

                $g.find('img[data-src]').unveil();

            });

            $image.swipe({
                allowPageScroll: 'vertical',

                swipe: function (event, direction, distance, duration, fingerCount, fingerData) {

                    if (direction === 'right') {
                        $nav.find('li.left-anchor > a:visible').trigger('click');
                    }

                    if (direction === 'left') {
                        $nav.find('li.right-anchor > a:visible').trigger('click');
                    }
                }
            });

            // to log the view upon loading
            _.bind($.pg_log, $image, 'image-preview-clicked')($image.find('.pg-preview-image').attr('src'));

        });

        // Handle pagination
        on('click', '.pg-portfolio ul.pagination a.page-numbers', function (e) {
            e.preventDefault();

            var $this = $(this),
                $container = $this.closest('.pg-enter-the-code'),
                $codeform = $container.find('.pg-the-code'),
                $page = $codeform.find('.the-page'),
                page = $.urlParam('page', $this.attr('href')) || 1;

            $page.val(page);

            _.bind($.pg_log, this, 'pagination-link-clicked')($this.attr('href'));

            $container.find('input.the-code').trigger('keyup');

            $('html, body').animate({
                scrollTop: $container.offset().top
            }, 100);
        });

        on('click', 'a.enter-new-code', function (e) {
            e.preventDefault();

            _.bind($.pg_log, this, 'new-code-button-clicked')();

            location.reload(true);
        });

        on('click', '.thumbnail.full-view ul.side-navigation li > a', function (e) {
            e.preventDefault();

            var $this = $(this),
                $container = $this.closest('.pg-enter-the-code'),
                $masonry = $container.find('.masonry'),
                $parent = $this.closest('ul.side-navigation'),
                $pagination = $container.find('ul.pagination'),
                side = $this.data('side'),
                next = $parent.data('next') || 0,
                max = $masonry.find('.grid-item').length - 1;

            if (side === 'left') {
                next -= 1;
            }

            if (side === 'right') {
                next += 1;
            }

            if (next === -1) {
                $('body').data('highlight-index', 'last');
                $pagination.find('li > a.prev.page-numbers:not(.first-page)').trigger('click');
                return;
            }

            if (next > max) {
                $('body').data('highlight-index', 'first');
                $pagination.find('li > a.next.page-numbers:not(.last-page)').trigger('click');
                return;
            }

            next = Math.max(0, next);
            next = Math.min(next, max);

            $masonry.find('.grid-item').eq(next).find('a').trigger('click', false);
        });

        // Handle the Code field
        on('keyup', '.pg-enter-the-code input.the-code', function (e) {
            var $input = $(this),
                $container = $input.closest('.pg-enter-the-code'),
                $target = $container.find('.pg-portfolio'),
                $codeform = $container.find('.pg-the-code'),
                settings = $input.data('settings'),
                regex = $input.data('regex'),
                _input = $input.val(),
                input = _input.toUpperCase().trim(),
                $loader = $('<i class="fa fa-spin fa-spinner m-l-10"></i>'),
                $form = $input.closest('form'),
                $alert = $container.find('.alert');

            // never ever submit the form by allowing the enter key to submit it
            if (e.which === 13) {
                e.preventDefault();
                return;
            }

            if ($input.data('doing-ajax')) {
                return;
            }

            if ($input.is(':hidden') && $container.data('the-code')) {
                input = $container.data('the-code');
            }

            if (!input) {
                return;
            }

            e.preventDefault();

            $input.val(input);

            $input.removeClass('valid').addClass('focused');

            $alert.addClass('hidden');

            if (!regex) {
                return;
            }

            var is_preview = $input.hasClass('is-preview') || _input.toLowerCase() === settings.toLowerCase();

            if (!is_preview) {

                regex = new RegExp('^' + regex + '$');

                if (!regex.test(input)) {
                    return;
                }

            }

            // reset the value to the non uppercase settings
            if (is_preview) {
                $input
                    .val(settings)
                    .addClass('is-preview');
            }

            $container
                .append($loader)
                .data('the-code', input)
                .addClass('loading');

            if ($.urlParam('PBGADMIN')) {
                $form.append($('<input type="hidden" name="admin_code"/>').val($.urlParam('PBGADMIN')));
            }

            var form_data = new FormData($form[0]);

            $input
                .addClass('valid')
                .prop('disabled', true)
                .data('doing-ajax', true);

            $.ajax({
                type: 'POST',
                url: $form.attr('action'),
                data: form_data,
                contentType: false,
                processData: false,
                cache: false,
            }).done(function (resp) {

                $input
                    .prop('disabled', false)
                    .data('doing-ajax', false);

                $loader.remove();

                if (resp.message) {
                    $alert.html(resp.message);
                }

                if (resp.error) {
                    $alert.removeClass('hidden');
                    return;
                }

                if (!resp.content) {
                    return;
                }

                var $content = $(resp.content);

                $codeform.hide();

                $container.removeClass('loading');

                $target.empty().append($content.children());

                $container.trigger('ajax.pg.loaded');

            }).error(function () {

                $input
                    .prop('disabled', false)
                    .data('doing-ajax', false);

                $loader.remove();
                $alert.removeClass('hidden');

            });
        });

        _.delay(function () {

            var $code = $('.pg-enter-the-code input.the-code');

            if ($code.data('scroll') === 'yes') {
                $code.focus();
            }

            $code.trigger('keyup');

            if ($.urlParam('PBGADMIN')) {
                $code.addClass('is-admin');
            }

        }, 200);

        on('submit', '.pg-enter-the-code form', function () {
            return false;
        });
    });

})(jQuery);

(function ($) {

    'use strict';

    $.pg_log = function( action, msg ) {

        var $container = $(this).closest('.the-gallery'),
            code = $container.data('code'),
            config = $container.data('config');

        if ( ! $container.length ) {
            return;
        }

        $.ajax({
            type : 'POST',
            url  : pg_data.ajaxurl,
            cache: false,
            data : {
                'action'    : 'pg-log-action',
                'csrf'      : pg_data['pg-log-action'],

                'pg-action' : action,
                'pg-code'   : code,
                'pg-config' : config,
                'pg-data'   : msg
            }
        });
    };

})(jQuery);
(function ($) {

    'use strict';

    var $document = $(document),
        on = function (event, selector, fn) {
            $document.on(event, selector, fn);
        };

    $(function () {

        on('hidden.bs.modal', '.modal.mp4-mailer', function(){
            var $modal = $(this),
                callback = $modal.data('hidden.bs.modal');


            if ( callback ) {
                callback();
            }

            $modal.find('form').removeData('payload');
        });

        on('show.bs.modal', '.modal.mp4-mailer', function(){
            var $modal = $(this),
                payload = $modal.data('payload');

            $modal.find('form').data('payload', payload);
        });

        on('submit', '.modal.mp4-mailer form', function( ){
            var $form = $(this),
                $modal = $form.closest('.modal.mp4-mailer'),
                mail = $form.find('input.email-address').val() || '',
                $loader = $('<i class="fa fa-spin fa-spinner"></i>'),
                $submit = $form.find('.btn-success');


            if ( ! mail.length ) {
                $modal.modal('hide');
                return false;
            }

            $submit.append( $loader ).addClass('disabled');

            $.ajax({
                type : 'POST',
                url  : pg_data.ajaxurl,
                cache: false,
                data : {
                    'action'    : 'pg-mp4-mailer',
                    'csrf'      : pg_data['pg-mp4-mailer'],
                    'mail'      : mail,
                    'payload'   : $form.data('payload')
                }
            }).success(function(){
                $loader.remove();
                $submit.removeClass('disabled');
                $modal.modal('hide');
            }).error(function(){
                $loader.remove();
                $submit.removeClass('disabled');
                $modal.modal('hide');
            });

            return false;
        });

    });

})(jQuery);
(function ($) {

    'use strict';

    var $document = $(document),
        on = function (event, selector, fn) {
            $document.on(event, selector, fn);
        };

    $(function () {

        on('click', 'button.social-share', function(e){

            e.preventDefault();

            var $this = $(this),
                $icon = $this.find('span'),
                icon = $icon.attr('class'),
                $gallery = $this.closest('.photobooth-gallery'),
                target        = $this.data('target');

            if ( $this.prop('disabled') ) {
                return;
            }

            _.bind( $.pg_log, this, 'share' )( $this.data('share') );

            if ( $this.data('type') === 'popup' ) {
                window.open( $this.data('target') );
                return;
            }

            if ( $this.data('type') === 'default' ) {
                location.href = $this.data('target');
                return;
            }

            // Do not download the file, but show Modal window instead if we have an video
            if ( $this.is('.download') && ! Modernizr.adownload && target.indexOf('mp4') !== -1 ) {

                $gallery
                    .find('.modal.mp4-mailer')
                    .data('hidden.bs.modal', function(){
                        $this.prop('disabled', false);
                        $icon.attr('class', icon );
                    })
                    .data('payload', $this.data('payload'))
                    .modal('show');

                return;
            }

            window.open( pg_data.baseurl + '/pg-social-share/' + $this.data('share') + '/?payload=' + $this.data('payload') );
        });

    });

})(jQuery);

(function ($) {

    'use strict';

    var $document = $(document),
        on = function (event, selector, fn) {
            $document.on(event, selector, fn);
        };


    $(function () {

        if (typeof (moment) === 'undefined') {
            return;
        }

        if (typeof jQuery.fn.datetimepicker === 'undefined') {
            return;
        }

        moment.locale('de');

        var $date_from = $('#date-from'),
            $date_to = $('#date-to'),
            $source = $('#source'),
            $submit_btn = $('.pg-controls .submit-btn'),
            $hour_selector = $('select.filter.last-x-hours');

        $date_from.datetimepicker({
            locale: 'de',
            'defaultDate': $date_from.data('defaultdate'),
            'format': 'YYYY-MM-DD HH:mm:ss',
            'keepOpen': false
        });

        $date_to.datetimepicker({
            useCurrent: false, //Important! See issue #1075
            locale: 'de',
            'defaultDate': $date_to.data('defaultdate'),
            'format': 'YYYY-MM-DD HH:mm:ss',
            'keepOpen': false
        });

        $date_from.on("dp.change", function (e) {
            $date_to.data("DateTimePicker").minDate(e.date);
            $hour_selector.val('0');
        });

        $date_to.on("dp.change", function (e) {
            $date_from.data("DateTimePicker").maxDate(e.date);
            $hour_selector.val('0');
        });

        $submit_btn.on('click', function (e) {
            e.preventDefault();

            var $t = $(this);

            $t.addClass('disabled');

            $.ajax({
                type: 'POST',
                cache: false,
                url: pg_statistic.ajaxurl,
                data: {
                    'action': 'pg-statistic-filter',
                    'from': $date_from.data("DateTimePicker").date().format('YYYY-MM-DD HH:mm:ss'),
                    'to': $date_to.data("DateTimePicker").date().format('YYYY-MM-DD HH:mm:ss'),
                    'source': $source.val(),
                    'hours': $hour_selector.val(),
                    'is_reset': $t.data('is-reset') || '0',
                    'csrf': pg_statistic.pg_statistic_filter
                }
            }).done(function (resp) {

                if (resp.reload) {
                    location.reload();
                }

            });
        });

        $('.pg-controls a.filter').on('click', function (e) {
            e.preventDefault();
            var $this = $(this);

            $this.addClass('disabled');

            $hour_selector.val('0');

            if ($this.hasClass('today')) {
                $date_from.data("DateTimePicker").date(moment().startOf('day'));
                $date_to.data("DateTimePicker").date(moment().endOf('day'));
            }

            if ($this.hasClass('week')) {
                $date_from.data("DateTimePicker").date(moment().startOf('week').startOf('day'));
                $date_to.data("DateTimePicker").date(moment().endOf('week').endOf('day'));
            }

            if ($this.hasClass('month')) {
                $date_from.data("DateTimePicker").date(moment().startOf('month').startOf('day'));
                $date_to.data("DateTimePicker").date(moment().endOf('month').endOf('day'));
            }

            $submit_btn.trigger('click');
        });

        $hour_selector.on('change', function () {
            var $this = $(this),
                val = $this.val();

            if (val === '0') {
                return;
            }

            $this.addClass('disabled');

            $date_from.data("DateTimePicker").date(moment().subtract(val, 'hours'));
            $date_to.data("DateTimePicker").date(moment());

            $submit_btn.trigger('click');
        });

        $('a.reset-btn').on('click', function (e) {

            e.preventDefault();

            $submit_btn.data('is-reset', '1');

            $submit_btn.trigger('click');
        });

        on('click', 'a.collapse-trigger', function (e) {
            e.preventDefault();

            var $target = $($(this).attr('href'));

            $target.collapse('toggle');

        });
    });

})(jQuery);
